import React from 'react'
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>404: not found</h1>
      <p>This page doesn't seem to exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage;